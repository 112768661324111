import { useAccount } from '@/components/authentication/AuthenticationProvider';
import { useCurrentRole } from '@/components/authentication/CurrentRoleContext';
import { Page } from '@/components/layout/Page';
import { FeedbackAndSupport } from 'insify-remote-component-loader';
import { ComponentProps } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

const ContentArea = styled.div`
  margin: 0 auto;
`;

const FeedbackAndSupportPage = () => {
  const { user } = useAccount();
  const role = useCurrentRole() as ComponentProps<typeof FeedbackAndSupport>['role'];
  const history = useHistory();
  const portal: ComponentProps<typeof FeedbackAndSupport>['portal'] = {
    displayName: 'Survey',
    name: 'survey'
  };

  return (
    <Page noScroll>
      <ContentArea>
        <FeedbackAndSupport account={user} role={role} history={history} portal={portal} />
      </ContentArea>
    </Page>
  );
};

export { FeedbackAndSupportPage };
