import useSWR from 'swr';
import { WidgetReference, DescriptionWidget } from '@/types';
import { RichTextView } from '@/components/shared/Form/RichTextView';
import { Pane, PreviewReportPane } from '@/components/shared/Pane';
import { Loader } from '@instech/components';
import { getOccurrencePrefixTitle } from '@/utils/text';

const getSubTitle = (title: string, widgetTitle: string, occurrenceId?: string, showTitle?: boolean) => {
  const occurrenceTitle = !!occurrenceId && widgetTitle.toLowerCase() !== title.toLowerCase() ? getOccurrencePrefixTitle(widgetTitle, true) : undefined;
  if (!occurrenceTitle && showTitle) {
    return widgetTitle;
  }
  return occurrenceTitle;
};

export const DescriptionReportPreview = ({ title, caseId, chapterId, widgetId, widgetTitle }: WidgetReference) => {
  const requestUrl = `/description/case/${caseId}/chapters/${chapterId}/widget/${widgetId}`;
  const { data } = useSWR<DescriptionWidget>(requestUrl);

  if (!data) return <Loader />;

  const subTitle = getSubTitle(title, widgetTitle, data.occurrenceId, data.showTitle);

  return (
    <PreviewReportPane title={title} subTitle={subTitle} caseId={caseId} chapterId={chapterId}>
      <RichTextView data={data} />
    </PreviewReportPane>
  );
};

interface ViewProps {
  title: string;
  widgetTitle: string;
  data: DescriptionWidget;
}

export const DescriptionReportView = ({ title, widgetTitle, data }: ViewProps) => {
  const subTitle = getSubTitle(title, widgetTitle, data.occurrenceId, data.showTitle);
  return (
    <Pane title={title} subTitle={subTitle}>
      <RichTextView data={data} />
    </Pane>
  );
};
